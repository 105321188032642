.post-error-message {
    margin-left: auto;
    min-height: 16px;
    margin-top: 16px;
    font-size: 12px;
    color: rgb(252 165 165);
    font-weight: 300;
}

.post-btns {
    margin-top: 12px;
    margin-left: auto;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 48px;
}

.post-btns > button {
    color: white;
    padding: 8px 24px;
    font-weight: 600;
    border-radius: 6px;
    transition-duration: 500ms;
    cursor: pointer;
}

.post-btns > button:hover {
    opacity: 0.5;
}

.post-delete-btn {
    background-color: rgb(248 113 113);
}

.post-submit-btn {
    background-color: rgb(38 38 38);
}
