.blog-body {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.blog-post-card {
    display: flex;
    justify-content: space-between;
}

.blog-post-title {
    display: flex;
    font-size: 14px;
    margin-bottom: 6px;
    font-weight: 500;
}

header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-logout-btn {
    font-size: 12px;
    color: rgb(212 212 212);
}

.header-logout-btn-content {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 12px;
}
