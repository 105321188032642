.dropzone-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.dropzone {
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    transition-duration: 500ms;
    border-width: 1px;
    border-style: dashed;
    height: 256px;
    border-color: rgb(82 82 82);
    border-radius: 8px;
}

.dropzone:hover {
    opacity: 0.5;
}

.dropzone-input {
    z-index: 1;
    cursor: pointer;
    height: 256px;
    width: 100%;
    opacity: 0;
}

.dropzone-input-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.dropzone-input-text {
    font-size: 12px;
    color: rgb(212 212 212);
}

.dropzone-img-card {
    display: flex;
    gap: 8px;
    margin-top: 16px;
    align-items: start;
}

.dropzone-img {
    height: 150px;
}

.dropzone-img-name {
    font-size: 12px;
    margin-top: 8px;
}
